.cards {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  .leftCard{
    margin-right: 90px;
    &:hover {
      --swing: -25px;
      --overlap: 25px;
      animation-duration: 0.5s;
      //animation-delay: 0s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-name: swing-backwards;
    }
  }
  .rightCard{
    margin-left: 90px;
    &:hover {
      --swing: 25px;
      --overlap: -25px;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-name: swing-forwards;
    }
  }
}

.card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
  height: 400px;
  width: 300px;
  border-radius: 25px;
  background-color: #7d3ccb;
  padding-bottom: 25px;

  img {
    margin-top: 15px;
    border-radius: 25px;
  }
  //transition: all 0.5s ease;
}

.middleCard {
  position: absolute;
}

@keyframes swing-forwards {
  0% {
    z-index: -1;
  }
  50% {
    z-index: 1;
    transform: translateX(var(--swing));
  }
  100% {
    z-index: 2;
    transform: translateX(var(--overlap)) scale(1.1);
  }
}

@keyframes swing-backwards {
  0% {
    z-index: -1;
  }

  50% {transform: translateX(var(--swing));}
  100% {
    z-index: 2;
    transform: scale(1.1);
  }
}