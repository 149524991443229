.selected__list {
  list-style-type: square;
  
  li {
    color:#000000;
    font-weight: bold;
  
    .selectedRegions {
      display: flex;
      width: 200px;
    }
  
    .deleteRegion:hover {
      cursor: pointer;
    }
  }
}

