.filterText {
  .MuiInputBase-root {
    input {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
    }
  }
}