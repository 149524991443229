.updateHeader {
  width: 100%;
  height: 100%;
}

.addUpdate {
  padding-top: 50px;
  height: 100%;
}
.updateGrid {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 95%!important;
  height: 80%!important;
}
.MuiDataGrid-cell--withRenderer {
  justify-content: flex-end!important;
}

.dataButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-self: flex-end;
  position: absolute;
  padding-right: 100px;
  margin-top: -20px;
  z-index: 1;
  gap: 20px;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}