.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.credentials {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;
  width: 50%;
}

.credentials:first-child {
  margin-bottom: 10rem;
}