.MuiBreadcrumbs-li {
  width: fit-content;
  a {
    font-family: 'Roboto', sans-serif; ;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #686970!important;
  }
}

.MuiBreadcrumbs-separator {
  width: fit-content;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #686970!important;
}

.breadcrumbsHeader {
  height: 83px;
  background-color: #EFEFEF;
  margin: 0 15px;

  .breadcrumbsItems {
    padding-left: 10px;
  }
  h3 {
    margin-top: 0;
  }
}