.App {
  text-align: center;
}

body {
  display: flex!important;
  align-items: baseline!important;
}

#root {
  flex-grow: 1;
  height: 100vh;
}

body, html{
  margin:0;
  padding:0;
  background-color: white!important;
}