.filter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 200px;
  background-color: #D9D9D9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0 0;
  padding-left: 20px;
  overflow: hidden;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  .MuiTypography-root {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
  }
  .filterTitle {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #262626;
    font-weight: 400;
  }
}

.closed {
  height: 0;
}

.filterText {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 30px;
}

.itemText {
  display: flex;
  flex-direction: column;
}

.textBoxFilter {
  background: #FFFFFF;
  border: 1px solid #686970;
  border-radius: 5px;
  width: 267px;
  height: 30px;
}

label {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #000000;
}


.hidden {
    display: none;
}