.addReleaseMain {
  background-color: #EFEFEF;
  display: flex;
  flex-direction: column;
  gap: 30px;

}
.generalInformation {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 145px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 98%;
  margin: 0 auto;
  padding-left: 20px;

  p.title {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #000000;
  }
}

.informations {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.infoItem {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  input {
    height: 35px;
    border: 1px solid #686970;
    border-radius: 5px;
  }
  [type="text"] {
    width: 400px;
  }
  [type="date"] {
    width: 161px;
  }

  .releaseDate {
    display: flex;
    background: #EFEFEF;
    border-radius: 5px;
    width: 142px;
    height: 35px;
    justify-content: center;
    align-items: center;
  }
}
.checksumInputs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;
  [type="text"] {
    width: 267px;
    height: 35px;

    background: #FFFFFF;
    border: 1px solid #686970;
    border-radius: 5px;
  }

  [type="button"] {
    width: 78px;
    height: 35px;

    background: #262626;
    border-radius: 5px;
    border-color: #262626;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    color: #FFFFFF;
  }
}

.releaseDetails {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 491px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 98%;
  margin: 0 auto;
  padding-left: 20px;
  gap: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  p.title {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #000000;
  }
  .regionLocal {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
}


.autocomplete {
  display: flex;
  flex-direction: column;
}
