.navBar {
  background-color: #262626;
  min-width: 175px!important;

  ul {
    list-style-type: none;
    padding-left: 0;
    width: 100%;
  }
  
  li {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 35px;
    line-height: 35px;
    width: 100%;
    color: #EFEFEF;
  
    &:hover {
      background-color: #383838;
      .line {
        background-color: #3E4DDB;
        width: 5px;
        height: 100%;
      }
    }
    .item {
      padding-left: 15px;
    }
  }

  a {
    text-decoration: none;
    color: #EFEFEF!important;
  }  
}

