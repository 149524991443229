.header {
  height: 60px;
  background-color: #686970;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cheatDiv {
  background-color: #262626;
  width: 175px;
}

.square {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  background-color: #262626;
  cursor: pointer;
}

.logoutIcon {
  align-self: center;
  transform: scale(1.5);
  fill: white;
  filter: invert(98%) sepia(3%) saturate(3432%) hue-rotate(187deg) brightness(117%) contrast(100%);
}

.nav-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  font-family: Roboto, sans-serif;
  .email {
    color: white;
  }
  .profilePic {
    cursor: pointer;
  }
}