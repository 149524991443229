.button {
  --background: #2B3044;
  --background-hover: #1E2235;
  --text: #fff;
  --shadow: rgba(0, 9, 61, .2);
  --paper: #5C86FF;
  --paper-lines: #fff;
  --trash: #E1E6F9;
  --trash-lines: #BBC1E1;
  --check: #fff;
  --check-background: #5C86FF;
  position: relative;
  border: none;
  outline: none;
  background: none;
  padding: 10px 24px;
  border-radius: 7px;
  min-width: 142px;
  max-width: 150px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  display: flex;
  color: var(--text);
  background: var(--btn, var(--background));
  box-shadow: 0 var(--shadow-y, 4px) var(--shadow-blur, 8px) var(--shadow);
  transform: scale(var(--scale, 1));
  transition: transform .3s, box-shadow .3s, background .3s;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  span {
    display: block;
    font-size: 14px;
    line-height: 25px;
    font-weight: 600;
    opacity: var(--span-opacity, 1);
    transform: translateX(var(--span-x, 0)) translateZ(0);
    transition: transform .4s ease var(--span-delay, .2s), opacity .3s ease var(--span-delay, .2s);
  }
  .trash {
    display: block;
    position: relative;
    left: -8px;
    transform: translate(var(--trash-x, 0), var(--trash-y, 1px)) translateZ(0) scale(var(--trash-scale, .64));
    transition: transform .5s;
    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 8px;
      width: 2px;
      border-radius: 1px;
      background: var(--icon, var(--trash));
      bottom: 100%;
      transform-origin: 50% 6px;
      transform: translate(var(--x, 3px), 2px) scaleY(var(--sy, .7)) rotate(var(--r, 0deg));
      transition: transform .4s, background .3s;
    }
    &:before {
      left: 1px;
    }
    &:after {
      right: 1px;
      --x: -3px;
    }
    .top {
      position: absolute;
      overflow: hidden;
      left: -4px;
      right: -4px;
      bottom: 100%;
      height: 40px;
      z-index: 1;
      transform: translateY(2px);
      &:before,
      &:after {
        content: '';
        position: absolute;
        border-radius: 1px;
        background: var(--icon, var(--trash));
        width: var(--w, 12px);
        height: var(--h, 2px);
        left: var(--l, 8px);
        bottom: var(--b, 5px);
        transition: background .3s, transform .4s;
      }
      &:after {
        --w: 28px;
        --h: 2px;
        --l: 0;
        --b: 0;
        transform: scaleX(var(--trash-line-scale, 1));
      }
      .paper {
        width: 14px;
        height: 18px;
        background: var(--paper);
        left: 7px;
        bottom: 0;
        border-radius: 1px;
        position: absolute;
        transform: translateY(-16px);
        opacity: 0;
        &:before,
        &:after {
          content: '';
          width: var(--w, 10px);
          height: 2px;
          border-radius: 1px;
          position: absolute;
          left: 2px;
          top: var(--t, 2px);
          background: var(--paper-lines);
          transform: scaleY(.7);
          box-shadow: 0 9px 0 var(--paper-lines);
        }
        &:after {
          --t: 5px;
          --w: 7px;
        }
      }
    }
    .box {
      width: 20px;
      height: 25px;
      border: 2px solid var(--icon, var(--trash));
      border-radius: 1px 1px 4px 4px;
      position: relative;
      overflow: hidden;
      z-index: 2;
      transition: border-color .3s;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 4px;
        height: var(--h, 20px);
        top: 0;
        left: var(--l, 50%);
        background: var(--b, var(--trash-lines));
      }
      &:before {
        border-radius: 2px;
        margin-left: -2px;
        transform: translateX(-3px) scale(.6);
        box-shadow: 10px 0 0 var(--trash-lines);
        opacity: var(--trash-lines-opacity, 1);
        transition: transform .4s, opacity .4s;
      }
      &:after {
        --h: 16px;
        --b: var(--paper);
        --l: 1px;
        transform: translate(-.5px, -16px) scaleX(.5);
        box-shadow: 7px 0 0 var(--paper), 14px 0 0 var(--paper), 21px 0 0 var(--paper);
      }
    }
    .check {
      padding: 4px 3px;
      border-radius: 50%;
      background: var(--check-background);
      position: absolute;
      left: 2px;
      top: 24px;
      opacity: var(--check-opacity, 0);
      transform: translateY(var(--check-y, 0)) scale(var(--check-scale, .2));
      transition: transform var(--check-duration, .2s) ease var(--check-delay, 0s), opacity var(--check-duration-opacity, .2s) ease var(--check-delay, 0s);
      svg {
        width: 8px;
        height: 6px;
        display: block;
        fill: none;
        stroke-width: 1.5;
        stroke-dasharray: 9px;
        stroke-dashoffset: var(--check-offset, 9px);
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--check);
        transition: stroke-dashoffset .4s ease var(--checkmark-delay, .4s);
      }
    }
  }
  &.delete {
    --span-opacity: 0;
    --span-x: 16px;
    --span-delay: 0s;
    --trash-x: 46px;
    --trash-y: 2px;
    --trash-scale: 1;
    --trash-lines-opacity: 0;
    --trash-line-scale: 0;
    --icon: #fff;
    --check-offset: 0;
    --check-opacity: 1;
    --check-scale: 1;
    --check-y: 16px;
    --check-delay: 1.7s;
    --checkmark-delay: 2.1s;
    --check-duration: .55s;
    --check-duration-opacity: .3s;
    .trash {
      &:before,
      &:after {
        --sy: 1;
        --x: 0;
      }
      &:before {
        --r: 40deg;
      }
      &:after {
        --r: -40deg;
      }
      .top {
        .paper {
          animation: paper 1.5s linear forwards .5s;
        }
      }
      .box {
        &:after {
          animation: cut 1.5s linear forwards .5s;
        }
      }
    }
  }
  &.delete,
  &:hover {
    --btn: var(--background-hover);
    --shadow-y: 5px;
    --shadow-blur: 9px;
  }
  &:active {
    --shadow-y: 2px;
    --shadow-blur: 5px;
    --scale: .94;
  }
}

@keyframes paper {
  10%,
  100% {
    opacity: 1;
  }
  20% {
    transform: translateY(-16px);
  }
  40% {
    transform: translateY(0);
  }
  70%,
  100% {
    transform: translateY(24px);
  }
}

@keyframes cut {
  0%,
  40% {
    transform: translate(-.5px, -16px) scaleX(.5);
  }
  100% {
    transform: translate(-.5px, 24px) scaleX(.5);
  }
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}