.divButton {
  display: flex;
  position: relative;
  width: fit-content;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 7px;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  background-color: #3e4ddb;
  svg {
    width: 17px;
    height: 17px;
    filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(34deg)
      brightness(103%) contrast(100%);
  }
}

.buttonText {
  display: flex;
  width: fit-content;
  align-items: center;
  padding-left: 45px;
  justify-content: flex-end;
  border-radius: 20px 20px 20px 20px;
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: white;
    padding-right: 10px;
  }
}
