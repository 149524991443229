.bannerPreview {
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  .previewTextContent {
    position: absolute;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-left: 20px;
    color: white;
  }

  .previewImg {
    width: 100%;
  }
}
