.bannerUpdate {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px 0;

  .selectBackgroundImage {
    margin-bottom: 20px;

    select {
      margin-bottom: 20px;
    }
  }

  .bannerInformation {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 0 auto;
    padding: 0 20px;
    margin: 0 25px;

    p.title {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 21px;
      line-height: 17px;
      margin-top: 15px;
      margin-bottom: 10px;
      color: #000000;
    }
  }

  select {
    display: block;
    margin-top: 10px;
  }

  .filesList {
    .fileItem {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 10px;

      .fileName {
        margin: 0;
      }
    }

    .resetButton {
      background: none !important;
      border: none;
      padding: 0 !important;
      color: #069;
      cursor: pointer;

      svg {
        height: 20px;
      }
    }
  }

  .bannerForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 300px;
  }

  .termItem {
    margin: 0 0 5px 0;
  }
}
