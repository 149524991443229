.autocomplete {
    position: relative;
    display: inline-block;
    width: 350px;

    .autocompleteItemsGroup {
      margin-top: 0.5rem;
      height: 300px;
      overflow-y: auto;
      overflow-x: clip;
      width: 350px;
    }    

    .autocompleteItems {
      width: 100%;
    }    
}
  
