.divNoButton {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttonNoIcon {
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  transform: scale(1.2);
  filter: invert(100%) sepia(13%) saturate(114%) hue-rotate(190deg) brightness(116%) contrast(100%);
}