.layout {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  overflow: hidden;
}
.layout__content {
  display: flex;
  flex-flow: row nowrap;
  flex-basis: 100%;
  min-height: 0;
}

main {
  margin: 0 auto;
  width: 100%;
  overflow-x: auto;
  background-color: #efefef;
}